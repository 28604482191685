import { defineStore } from 'pinia'
import type { FilterItem, Section } from '@academica-box/components/Filter/types'
import { FilterKeys } from '@academica-box/types'
import type { Filters } from '@/types'
import type { SortFilter } from '@/api/filters'

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    filters: null,
  } as {
    filters: Filters | null;
  }),

  getters: {
    flattenCompanies(state) {
      const companies: Section[] = state.filters?.[FilterKeys.companies] || []

      return companies.reduce((acc: FilterItem[], item) => {
        acc = [...acc, ...item.items]

        return acc
      }, [])
    },

    computedFilters(state): Filters | null {
      const route = useRoute()
      const copyQuery = { ...route.query }
      delete copyQuery[FilterKeys.companies]

      const isSomeFilterSelected = Object.keys(copyQuery).length

      const companies: Filters[FilterKeys.companies] = state.filters?.[FilterKeys.companies].map((company) => {
        return {
          ...company,
          items: company.items.map((item) => {
            return {
              ...item,
              courses: isSomeFilterSelected ? 0 : item.courses
            }
          })
        }
      }) || []

      if (!state.filters) {
        return null
      }

      return {
        ...state.filters,
        [FilterKeys.companies]: companies,
      }
    }
  },

  actions: {
    async getFilters(filters?: SortFilter) {
      const { $api } = useNuxtApp()

      const response = await $api.filters.get(filters)

      this.filters = response

      return response
    },
  },
})
